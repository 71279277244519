.main {
    margin: auto;
    height: 100%;
    text-align: center;
    min-height: 100vh;
    /* background-image: url('../../assets/images/hero-bg.jpg'); */
    background-color: #000;
}

.left_side {
    overflow: hidden;
    display: flex;
    align-items: center;
    min-width: 40%;
    min-height: 100vh;
    margin: auto;
    text-align: center;
}

.right_side {
    width: 100%;
    max-width: 500px;
    margin: auto;
}

.description {
    color: white;
    font-size: 24px;
}

@media only screen and (max-width: 800px) {
    .left_side {
        padding: 0px;
        width: 100%;
    }

    .right_side {
        padding: 0px;
        width: 100%;
        padding: 40px 10px;
    }

    .description {
        font-size: 18px;
    }
}