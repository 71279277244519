body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiAppBar-colorPrimary {
  background-color: #333333 !important;
}

.hm {
  padding: 10px !important;
  display: inline-block;
}

@media(max-width:500px) {
  #top-menu {
    justify-content: center;
  }

  #top-menu>li {
    margin: initial !important;
  }

  .site-branding {
    text-align: center;
  }
}

.MuiStepper-root {
  padding: 0 !important;
}

.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  font-size: 10px;
}